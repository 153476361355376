import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import * as auth from './ducks/auth.duck';
import * as users from './ducks/users.duck';
import * as profile from './ducks/profile.duck';
import * as companies from './ducks/companies.duck';
import * as transactions from './ducks/transactions.duck';
import * as promocodes from './ducks/promocodes.duck';

import * as categories from './ducks/categories.duck';
import * as products from './ducks/product.duck';
import * as productTypes from './ducks/productType.duck';
import * as orders from './ducks/orders.duck';
import * as agreement from './ducks/agreement.duck';
import * as yaLocations from './ducks/yaLocations.duck';
import * as stores from './ducks/store.duck';
import * as cart from './ducks/cart.duck';
import * as blog from './ducks/blog.duck';
import * as messages from './ducks/messages.duck';
import * as tariff from './ducks/tariff.duck';
import * as productsCatalog from './ducks/product-catalog.duck';
import * as tariffs from './ducks/tariffs.duck';

import { metronic } from '../../_metronic';

export type TAppActions =
  | auth.TActions
  | companies.TActions
  | transactions.TActions
  | promocodes.TActions
  | users.TActions
  | profile.TActions
  | categories.TActions
  | products.TActions
  | productTypes.TActions
  | orders.TActions
  | agreement.TActions
  | yaLocations.TActions
  | stores.TActions
  | cart.TActions
  | blog.TActions
  | tariff.TActions
  | messages.TActions
  | productsCatalog.TActions
  | tariffs.TActions;

export interface IAppState {
  i18n: typeof metronic.i18n.reducer;
  builder: typeof metronic.builder.initialState;
  auth: auth.IInitialState & PersistPartial;
  companies: companies.IInitialState & PersistPartial;
  transactions: transactions.IInitialState & PersistPartial;
  promocodes: promocodes.IInitialState & PersistPartial;
  users: users.IInitialState & PersistPartial;
  profile: profile.IInitialState & PersistPartial;
  categories: categories.IInitialState & PersistPartial;
  products: products.IInitialState & PersistPartial;
  productTypes: productTypes.IInitialState & PersistPartial;
  orders: orders.IInitialState & PersistPartial;
  agreement: agreement.IInitialState;
  yaLocations: yaLocations.IInitialState;
  stores: stores.IInitialState & PersistPartial;
  cart: cart.IInitialState & PersistPartial;
  blog: blog.IInitialState & PersistPartial;
  messages: messages.IInitialState & PersistPartial;
  tariff: tariff.IInitialState & PersistPartial;
  productsCatalog: productsCatalog.IInitialState & PersistPartial;
  tariffs: tariffs.IInitialState & PersistPartial;
}

export const rootReducer: (
  state: IAppState,
  action: TAppActions
) => IAppState = combineReducers<IAppState, TAppActions>({
  auth: auth.reducer,
  companies: companies.reducer,
  transactions: transactions.reducer,
  promocodes: promocodes.reducer,
  users: users.reducer,
  profile: profile.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  categories: categories.reducer,
  products: products.reducer,
  productTypes: productTypes.reducer,
  orders: orders.reducer,
  agreement: agreement.reducer,
  yaLocations: yaLocations.reducer,
  stores: stores.reducer,
  cart: cart.reducer,
  blog: blog.reducer,
  tariff: tariff.reducer,
  messages: messages.reducer,
  productsCatalog: productsCatalog.reducer,
  tariffs: tariffs.reducer,
});

export function* rootSaga() {
  yield all(
    [
      auth.saga,
      companies.saga,
      transactions.saga,
      promocodes.saga,
      users.saga,
      profile.saga,
      categories.saga,
      stores.saga,
      products.saga,
      productTypes.saga,
      orders.saga,
      agreement.saga,
      yaLocations.saga,
      cart.saga,
      blog.saga,
      messages.saga,
      tariff.saga,
      productsCatalog.saga,
    ].map(saga => fork(saga))
  );
}
