import axios from 'axios';
import moment from 'moment';

export const getResponseMessage = (e: any) => e?.response?.data?.message || 'Network error';

// example: 10000 => '10 000'
export const formatAsThousands = (data: string | number) =>
  String(data).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${'\u00A0'}`);

export const makeAxiosGetRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .get(url)
    .then(res => setData(res.data.data))
    .catch(err => {
      if (err) setErr(String(err));
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const makeAxiosPostRequest = (
  url: string,
  data: any | undefined,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>,
  options?: any
) => {
  if (!options) options = {};
  setLoading(true);
  axios
    .post(url, data, options)
    .then(res => setData(res))
    .catch(err => {
      if (err) setErr(String(err));
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const makeAxiosDeleteRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .delete(url)
    .then(res => setData(res))
    .catch(err => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const thousands = (inData: string) => {
  return inData.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${'\u00A0'}`);
};

export const countLettersInString = (str: string, letter: string) => {
  let n = 0;
  for (const l of str) {
    if (l === letter) n += 1;
  }
  return n;
};

export const isEmail = (str: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};

export const appendQuerys = (url: string, querys: string[]) => {
  let newUrl = `${url}`;
  for (let i = 0; i < querys.length; i += 1) {
    newUrl += `${i === 0 ? '?' : '&'}${querys[i]}`;
  }
  return newUrl;
};

export const maskPhone = (phone: string = '', country_code = '') => {
  if (!phone) {
    return '';
  }

  if (phone[0] !== '+') {
    phone = '+' + country_code + +phone;
  }

  const val = phone.replace(/\D/g, '');

  return `+${val[0]} ${val.slice(1, 4)} ${val.slice(4, 7)} ${val.slice(7, 9)} ${val.slice(9)}`;
};

export const cleanPhone = (phone: string, country = '', length = 0) => {
  while (length >= phone.length) {
    phone += '0';
  }
  return phone
    .replace(country, '')
    .replace(/ /g, '')
    .replace(/[{()}]/g, '')
    .trim();
};

export const isDatePast = (date: string): boolean => moment(date).diff(moment()) <= 0;

export const titlesDays = ['день', 'дня', 'дней'];

export const declOfNum = (isNumber: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  if (isNumber > -1) {
    return titles[
      isNumber % 100 > 4 && isNumber % 100 < 20
        ? 2
        : cases[isNumber % 10 < 5 ? isNumber % 10 : 5]
    ];
  }
  return '';
};
