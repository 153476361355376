import React from 'react';
import clsx from 'clsx';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: () => {} | void;
}

const useStyles = makeStyles((theme: Theme) => ({
  isLoading: {
    paddingRight: '3.5rem!important',
  },
  button: {
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: '#81cf34',
    },
  },
}));

const noop = () => { };

const OutlinedButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'button',
  disabled = false,
  loading = false,
  onClick = noop,
}) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      variant="outlined"
      color="primary"
      disabled={disabled}
      onClick={onClick}
      className={clsx({
        'btn btn-elevate': true,
        [classes.button]: true,
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark': loading,
        [classes.isLoading]: loading,
      })}
    >
      {children}
    </Button>
  );
};

export default OutlinedButtonWithLoader;
