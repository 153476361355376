import axios from 'axios';
import {
  CREATE_USER_URL,
  getUserUrl,
  SEND_INVITE_URL,
  delUserUrl,
  uploadUserAvatarUrl,
  GET_USERS_URL,
} from '../constants';
import { IUserAddProps } from '../interfaces/user';
import { appendQuerys } from '../utils/utils';

// export function getUsersByRole(
//   page: number,
//   perPage: number,
//   roles: string,
//   filter: TUserFilter
// ) {
//   let url = `/api/users/search?page=${page}&per_page=${perPage}&roles=${roles}`;

//   if (Object.keys(filter).length > 0) {
//     for (const key of Object.keys(filter)) {
//       if (filter[key].length > 0) {
//         url += `&${key}=${filter[key].join(',')}`;
//       }
//     }
//   }

//   return axios.get(url);
// }

export function getUsersByRole(
  page: number,
  perPage: number,
  roles: string,
  country_id?: number,
  has_email?: boolean
) {
  return axios.get(`${GET_USERS_URL}`, {
    params: {
      page,
      per_page: perPage,
      roles,
      country_id,
      has_email: typeof has_email === 'boolean' ? has_email : undefined,
    },
  });
}

export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data);
}

export function editUser(id: number, data: any) {
  let url = `/api/user/${id}/edit`;
  const querys = [];
  if (data.languages && data.languages.length > 0) {
    querys.push(`languages=${data.languages.join(',')}`);
  } else {
    querys.push(`languages=%20`);
  }
  if (data.country) {
    querys.push(`country=${data.country}`);
  } else {
    querys.push(`country=%20`);
  }
  if (!data.isAlive) {
    querys.push(`death_date=1900-01-01`);
  }
  url = appendQuerys(url, querys);
  return axios.put(url, data);
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}?email=${data}`);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function uploadUserAvatar({ photo, id }: { photo: FormData; id: string }) {
  return axios.post(uploadUserAvatarUrl(id), photo);
}

export function deleteUserPhoto(id: string) {
  return axios.delete(`/api/user/${id}/delete_photo`);
}

export function deleteMePhoto() {
  return axios.delete(`/api/me/delete_photo`);
}
