import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeAxiosPostRequest } from '../../../utils/utils';

export const useAxiosRegistrations = () => {
  const intl = useIntl();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [requestedType, setRequestedType] = useState<'email' | 'phone' | null>(null);

  const makeRequest = useCallback(
    (type: 'email' | 'phone', submitValues: any, countryData: any) => {
      const commonData = {
        roles: [submitValues.role],
        fio: submitValues.fio,
        locale: intl.locale,
      };

      let url = `/api/users/code_register_sms`;
      let data = null;

      if (type === 'email') {
        data = {
          ...commonData,
          email: submitValues.email,
        };
      }
      if (type === 'phone') {
        url += `?country_name=${countryData.en_name}`;
        url += `&country_code=${countryData.code}`;
        data = {
          roles: commonData.roles,
          lang: 'en',
          countryId: String(countryData.id),
          phoneNumbers: submitValues.phone,
          platform: 'web'
        };
      }

      setRequestedType(type);
      makeAxiosPostRequest(url, data, setData, setLoading, setErr);
    },
    [intl]
  );

  return [data, loading, err, makeRequest, requestedType];
};
