import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import { getOrdersByStatus, getOrderurl } from '../constants';

export const getOrders = (
  statuses: string,
  page: number,
  per_page: number,
  userId?: number
) => {
  let url = `${getOrdersByStatus(statuses)}&page=${page}&per_page=${per_page}`;
  if (userId) url += `&user_id=${userId}`;
  return axios.get(url);
};

export const deleteOrder = (id: number) => axios.delete(getOrderurl(id));

export const editOrder = (id: number, order: IOrderForEdit) =>
  axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));
