import axios from 'axios';
import { ICreateSubscription, ITariffEditProps } from '../interfaces/tariff';
import { UserRoles } from '../interfaces/user';

export const getTariffsUrl = '/api/tariffs';
export const editTariffDiscountsUrl = (id: number) => `/api/tariff/${id}`;
export const editTariffPriceUrl = (id: number) => `/api/tariff_price/${id}`;
export const editTariffPeriodUrl = (id: number) => `/api/tariff_period/${id}`;

const createTariffSubscription = ({
  tariff_id,
  days,
  payment_method_id,
}: ICreateSubscription) => {
  return `/api/stripe/add_subscription?days=${days}&tariff_id=${tariff_id}&payment_method_id=${payment_method_id}`;
};

export const getTariffs = () => {
  return axios.get(`/api/tariffs`);
};

export const editTariff = (id: number, data: ITariffEditProps) =>
  axios.put(`api/tariff/${id}`, data);

export const getTariffById = (id: number) => axios.get(`api/tariff/${id}`);

export const createPayment = (amount: number, tariff_id: number) =>
  axios.get(`/api/stripe/add_payment?amount=${amount}&tariff_id=${tariff_id}`);

export const createSubscription = (data: ICreateSubscription) =>
  axios.post(createTariffSubscription(data));

export const getStripeUser = (data: ICreateSubscription) =>
  axios.get(createTariffSubscription(data));

export const editPeriodTariff = (
  id: number,
  data: string | number | null,
  type: 'period' | 'price' | 'discounts'
) => {
  if (type === 'discounts') {
    return axios.put(editTariffDiscountsUrl(id), { discountsPerDay: data || null });
  }
  if (type === 'price') {
    return axios.put(editTariffPriceUrl(id), { price: data || undefined });
  }
  return axios.put(editTariffPeriodUrl(id), { days: data || undefined });
};

export const buyTariffGem = async (id: number, days: number) =>
  axios.post(`/api/buy_tariff/${id}?days=${days}`);
