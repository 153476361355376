import axios from 'axios';

import { API_SEARCH_LOCATION_URI } from '../constants';

const callLocationApi = axios.create({
  baseURL: `${API_SEARCH_LOCATION_URI}`,
  headers: {
    Accept: '*/*',
  },
});

export const getLocation = (q, locale) => {
  const locales = {
    // ru: 'ru',
    en: 'en',
  };

  return callLocationApi.get(
    `${API_SEARCH_LOCATION_URI}&address=${q}sensor=true&language=${locales[locale]}`
  );
};

export const getLocationByCoord = ({ lat, lng }) =>
  callLocationApi.get(`${API_SEARCH_LOCATION_URI}&latlng=${lat},${lng}`);
