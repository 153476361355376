import { useCallback, useState } from 'react';
import axios from 'axios';
import { isEmail } from '../../../utils/utils';

export const useAxiosCheckInSystem = (countryData: any) => {
  const [isFound, setIsFound] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [requestedType, setRequestedType] = useState<null | 'email' | 'phone'>(null);

  const makeCheckInSystemRequest = useCallback((submitValues: any, tab: number) => {
    const baseUrl = '/api/_p/user/find_in_system';
    let url = null;
    if (tab === 0) {
      const emailOrLogin = isEmail(submitValues.email) ? 'email' : 'login';
      url = `${baseUrl}?${emailOrLogin}=${submitValues.email}`;
      setRequestedType('email');
    } else {
      url = `${baseUrl}?phone=${submitValues.phone}`;
      setRequestedType('phone');
    }
    setLoading(true);
    axios
      .get(url)
      .then(() => {
        setIsFound(true);
      })
      .catch(err => {
        if (err) {
          if (err.response.data.status === 404) {
            setIsFound(false);
          } else {
            setErr(err);
          }
        }
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
      });


  }, []);

  return { makeCheckInSystemRequest, loading, checkInSystemErr: err, isFound, requestedType };
};
