import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { ITariff } from '../../interfaces/tariff';

const SET_TARIFFS = 'tariffs/SET_TARIFFS';

export interface IInitialState {
  tariffs: ITariff[];
}

const initialState: IInitialState = {
  tariffs: [],
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'tariffs', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_TARIFFS: {
        return {
          ...state,
          tariffs: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setTariffs: (payload: ITariff[]) => createAction(SET_TARIFFS, payload),
};

export type TActions = ActionsUnion<typeof actions>;
