import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { getTransactions } from '../../crud/transactions.crud';
import { getResponseMessage } from '../../utils/utils';
import { ITransaction } from '../../interfaces/transactions';
import { IFilteredData } from '../../interfaces/promocodes';

const CLEAR_FETCH = 'transactions/CLEAR_FETCH';
const FETCH_REQUEST = 'transactions/FETCH_REQUEST';
const FETCH_SUCCESS = 'transactions/FETCH_SUCCESS';
const FETCH_FAIL = 'transactions/FETCH_FAIL';
const CLEAR_TRANSACTIONS = 'transactions/CLEAR_TRANSACTIONS';
const CLEAR_PAGINATION = 'transactions/CLEAR_PAGINATION';
const CLEAR_DEL_TRANSACTION = 'transactions/CLEAR_DEL_TRANSACTION';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  transactions: any;
  loading: boolean;
  success: boolean;
  error: string | null;
  delTransactionSuccess: boolean;
  delTransactionError: string | null;

}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  transactions: null,
  loading: false,
  success: false,
  error: null,
  delTransactionSuccess: false,
  delTransactionError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'transactions', whitelist: ['transactions', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_TRANSACTIONS: {
        return { ...state, transactions: [] };
      }

      case CLEAR_PAGINATION: {
        return { ...state, ...defaultPaginatorProps };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          transactions: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          transactions: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number; isFilter?: boolean, filterData?: IFilteredData, user_id?: string, }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ITransaction[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  clearTransactions: () => createAction(CLEAR_TRANSACTIONS),
  clearPagination: () => createAction(CLEAR_PAGINATION),
  clearDelTransaction: () => createAction(CLEAR_DEL_TRANSACTION)
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; isFilter?: boolean; filterData?: IFilteredData, user_id?: string };
}) {
  try {
    const { data }: { data: IServerResponse<ITransaction[]> } = yield call(() =>
      getTransactions(payload.page, payload.perPage, payload.isFilter, payload.filterData, payload.user_id,)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
}
