import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const { profile, users, legal, settings, catalog, guestBlog } = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [profile],
  },
});

// const getManagerMenu = () => ({
//   header: {
//     self: {},
//     items: [],
//   },
//   aside: {
//     self: {},
//     items: [profile],
//   },
// });

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [users, settings, legal],
  },
});

// const getVendorMenu = () => ({
//   header: {
//     self: {},
//     items: [],
//   },
//   aside: {
//     self: {},
//     items: [profile, logout],
//   },
// });

// const getBuyerMenu = () => ({
//   header: {
//     self: {},
//     items: [],
//   },
//   aside: {
//     self: {},
//     items: [profile, logout],
//   },
// });

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog, guestBlog],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles && userRoles.includes('ROLE_ADMIN')) {
      return getAdminMenu();
    }
    return getDefaultMenu();
  }
  return getGuestMenu();
};

export default getMenuConfig;
