import React from 'react';
import { CircularProgress, MenuItem, TextField } from '@material-ui/core';

interface IProps {
  data: Array<any> | null;
  selectedValue: any | null;
  setSelectedValue: any;
  loading?: boolean;
  label: string;
  error?: boolean;
  helperText?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  renderItemText?: (item: any) => string;
  disabled?: boolean;
}

const SelectField: React.FC<IProps> = props => {
  const {
    data,
    selectedValue,
    setSelectedValue,
    loading = false,
    label,
    error = false,
    helperText,
    variant = 'outlined',
    disabled,
    renderItemText,
  } = props;
  return (
    <TextField
      select
      margin='normal'
      label={label}
      value={selectedValue}
      onChange={e => setSelectedValue(e.target.value)}
      name='parent'
      variant={variant}
      disabled={loading || !data || disabled}
      error={error}
      helperText={helperText}
      placeholder='placeholder'
      InputProps={{
        endAdornment: loading ? (
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 10,
              marginRight: -4.5,
              marginTop: -1,
            }}
          >
            <CircularProgress size={15} />
          </div>
        ) : null,
      }}
    >
      {data?.map(item => (
        <MenuItem key={item.id} value={item.id}>
          {renderItemText ? renderItemText(item) : item.en_name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectField;
