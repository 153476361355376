import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

export const useShowSuccesses = (possibleSuccesses: Array<{ when: boolean; msg: string }>) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const whens = useMemo(() => possibleSuccesses.map(el => el.when), [possibleSuccesses]);
  useEffect(() => {
    const trueSuccesses = possibleSuccesses.filter(el => el.when);
    for (const trueSuccess of trueSuccesses) {
      enqueueSnackbar(`${intl.formatMessage({ id: trueSuccess.msg })}`, { variant: 'success' });
    }
  }, [enqueueSnackbar, ...whens]);
};
